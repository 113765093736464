<template>
  <div>
    <router-view v-if="flag"></router-view>
  </div>
</template>
<script>
import { searchQueInfo } from '@/api/questionnaire.js'
export default {
  data() {
    return {
      flag: false,
    }
  },
  created() {
    searchQueInfo(this.$route.query.id).then((res) => {
      this.$store.dispatch('setQuestionnaireInfo', res.result)
      this.flag = true
    })
  },
  computed: {},
  methods: {},
}
</script>
<style lang="scss" scoped></style>
