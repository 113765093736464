import request from '@/utils/request'

// 查询问卷列表
export function getQuestionnaireList(userId) {
  return request({
    url: `/questionnaire/questionnaire/${userId}/queryQueListByUserId`,
    method: 'get',
  })
}

// 查询问卷信息
export function getQuestionnaireInfo(userId,questionnaireId) {
  return request({
    url: `/questionnaire/questionnaire/${userId}/${questionnaireId}/queryQueByUserId`,
    method: 'get',
  })
}

// 提交问卷
export function submitQue(params) {
  return request({
    url: `/questionnaire/questionnaireUserAns/submitQue`,
    method: 'post',
    params
  })
}

// 问卷暂存
export function saveQue(params) {
  return request({
    url: `/questionnaire/questionnaireUserAns/storageSub`,
    method: 'post',
    params
  })
}

// 查询问卷基础信息
export function searchQueInfo(queId) {
  return request({
    url: `/questionnaire/questionnaire/${queId}/queInfo`,
    method: 'get',
  })
}

// 查询列表信息复现
export function getInfo(userId,questionnaireId) {
  return request({
    url: `/questionnaire/questionnaire/${userId}/${questionnaireId}/queryQueInfoValue`,
    method: 'get',
  })
}